import React from "react";
import { Grid, Segment, Header } from "semantic-ui-react";
import { AppImage } from "../../styled/image";
import { GridColumn } from "../../styled/gridColumn";
import { IMAGE_URL } from "../../../utils/backendRequests";

export const About = () => (
	<Grid.Row centered>
		<GridColumn>
			<Segment padded="very">
				<Header as="h2" textAlign="center">
					Welcome to CaMP
					<Header.Subheader>
						Cancer Metabolic Pathway viewer
					</Header.Subheader>
				</Header>
				<p>
					The Cancer Metabolic Pathway viewer <b>(CaMP)</b> is a
					freely available electronic database that contains metabolic
					activity profiles of cancer cells in vivo, non-malignant
					(stroma) cells within tumors, normal tissues and cancer
					cells in vitro.
				</p>
				<ul>
					<li>
						Metabolic profiles of cancer cells and stromal cells for
						20 solid tumor types is estimated using a data
						deconvolution approach (
						<a
							href="https://cancerres.aacrjournals.org/content/81/7/1802"
							target="_blank"
							rel="noopener noreferrer"
						>
							Ghoshdastider, 2021
						</a>
						) based on transcriptomes of 7865 tumors obtained from
						the cancer genome atlas (TCGA).
					</li>
					<li>
						Metabolic profiles of normal tissues is estimated using
						transcriptomes of 815 normal tissue samples from TCGA
						and genotype-tissue expression (GTEX).
					</li>
					<li>
						620 cancer cell line from the cancer cell line
						encyclopedia (CCLE) transcriptomes are used to estimate
						the metabolic activity of cancer cells in vitro.
					</li>
					<li>
						The database contains KEGG maps of 55 metabolic pathways
						based on 1845 metabolic genes.
					</li>
				</ul>
				<AppImage
					src={IMAGE_URL + "/homepage_banner.png"}
					alt="home page banner"
				/>
			</Segment>
		</GridColumn>
	</Grid.Row>
);
