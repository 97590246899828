import React from "react";
import Plot from "react-plotlyjs-ts";
import { Dimmer, Segment, Loader } from "semantic-ui-react";
import { heatmap } from "./plotConfig.json";

export interface InterfaceHeatmap {
	x: string[];
	y: string[];
	z: number[][];
	text: string[][];
	colorscale: {
		[key: string]: any;
	};
}

interface Props {
	dataHeatmap: InterfaceHeatmap;
	loading: boolean;
	handleHeatmapClick: (yValue: string, xValue: string) => void;
}

export const HeatMap = (props: Props) => (
	<Dimmer.Dimmable dimmed={props.loading} blurring>
		<Segment>
			<Loader active={props.loading} size="massive" />
			<Plot
				data={[
					{
						...props.dataHeatmap,
						...heatmap.data
					}
				]}
				layout={{
					height: Math.max(400, props.dataHeatmap.y.length * 20),
					...heatmap.layout
				}}
				config={heatmap.config}
				onClick={e =>
					props.handleHeatmapClick(e.points[0].y, e.points[0].x)
				}
			/>
		</Segment>
	</Dimmer.Dimmable>
);
