import React from "react";
import {
	Grid,
	Header,
	Label,
	AccordionTitleProps,
	Tab,
	Menu,
	Icon,
	Segment,
	Container
} from "semantic-ui-react";
import { motion } from "framer-motion";

import { AppImage } from "./image";
import { PlotBar } from "../plots/plotBar";
import { GridColumn } from "./gridColumn";
import { InterfaceExpression } from "../../utils/interfaces";
import { requestImage } from "../../utils/backendRequests";
import { OPTIONS } from "../../utils/constants";

interface Props {
	listComparisionType: string[];
	currentPathwayKeggId: string;
	currentTumorType: string;
	dataBarplot: InterfaceExpression[];
}

interface State {
	activeComparisionType: string;
}

const AnimatedArrowDown = () => (
	<Container textAlign="center" as="a" href="#map">
		Pathway Maps
		<br />
		<motion.div
			animate={{
				y: [0, 2, 5, 7, 10, 7, 5, 2, 0]
			}}
			transition={{
				duration: 1,
				loop: 2,
				repeatDelay: 1
			}}
		>
			<Icon name="arrow down" />
		</motion.div>
	</Container>
);

export class PathwayBrowseImages extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			activeComparisionType: this.props.listComparisionType[0]
		};
	}

	handleAccordianClick = (titleProps: AccordionTitleProps) => {
		titleProps.index === this.state.activeComparisionType
			? this.setState({ activeComparisionType: "" })
			: this.setState({
					activeComparisionType: titleProps.index as string
			  });
	};

	render() {
		const panes = OPTIONS.filter(option =>
			this.props.listComparisionType.includes(option.value as string)
		).map(val => {
			return {
				menuItem: (
					<Menu.Item key={val.text as string}>
						<Header as="h3">
							<Label color={val.color1}>{val.arrtext[0]}</Label>{" "}
							<small>{val.arrtext[1]}</small>
							<Label color={val.color2}>{val.arrtext[2]}</Label>
							<Label size="mini">
								{this.props.currentTumorType}
							</Label>
						</Header>
					</Menu.Item>
				),
				render: () => (
					<Tab.Pane attached={false}>
						<AppImage
							src={requestImage(
								val.value as string,
								this.props.currentPathwayKeggId,
								this.props.currentTumorType
							)}
							alt={
								"comparision type: " +
								val.text +
								", tumor type: " +
								this.props.currentTumorType +
								", pathwayId: " +
								this.props.currentPathwayKeggId
							}
						/>
					</Tab.Pane>
				)
			};
		});

		return (
			<>
				<Grid.Row centered>
					<GridColumn>
						<Segment padded="very">
							<Header as="h3">
								<Icon name="chart bar" />
								Fold Change
							</Header>
							{this.props.dataBarplot.length > 0 ? (
								<>
									<PlotBar {...this.props} />
									<AnimatedArrowDown />
								</>
							) : null}
						</Segment>
					</GridColumn>
				</Grid.Row>
				<Grid.Row centered>
					<GridColumn>
						<Segment id="map" padded="very">
							<Header as="h3">
								<Icon name="image" />
								Pathway Image
							</Header>
							<Tab panes={panes} />
						</Segment>
					</GridColumn>
				</Grid.Row>
			</>
		);
	}
}
