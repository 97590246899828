import React from "react";

import { About } from "./about";
import { Features } from "./features";
import { Banner } from "./banner";

export const HomePage = () => (
	<>
		<Banner />
		<Features />
		<About />
	</>
);
