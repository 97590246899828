import React from "react";
import axios from "axios";
import {
	Segment,
	Form,
	Dropdown,
	Checkbox,
	DropdownItemProps,
	Header,
	Icon,
	Dimmer,
	Loader
} from "semantic-ui-react";
import {
	InterfaceMappingGenePathways,
	InterfaceFoldchangePerComparisiontype
} from "../../../utils/interfaces";
import {
	requestMappingGenePathwayOptions,
	requestHeatmapFoldchangeData
} from "../../../utils/backendRequests";
import { browserHistory } from "../../../utils/browserHistory";
import { InterfaceHeatmap, HeatMap } from "../../plots/plotHeatmap";
import { ROUTES } from "../../../utils/routes";
import { ColorScale, OPTIONS } from "../../../utils/constants";

interface Props {
	handleHeatmapClick: (
		comparisionType: string,
		pathwayId: string,
		tumorType: string
	) => void;
	resetBarPlotData: () => void;
	isFetchingDataBarPlot: boolean;
	currentComparisionType: string;
}

interface InterfaceFilterDataHeatmap extends InterfaceHeatmap {
	keggIds: string[];
}

interface State {
	comparisionTypeOptions: DropdownItemProps[];
	currentComparisionType: string;
	toFilterGenes: boolean;
	filterGeneOptions: InterfaceMappingGenePathways[];
	currentFilterGeneOption: InterfaceMappingGenePathways;
	dataHeatmap: InterfaceFoldchangePerComparisiontype;
	isFetchingDataHeatmap: boolean;
}

export class HeatMapMenu extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			comparisionTypeOptions: OPTIONS,
			currentComparisionType: this.props.currentComparisionType,
			toFilterGenes: false,
			filterGeneOptions: [],
			currentFilterGeneOption: {
				text: "",
				value: "",
				pathwayIndexes: []
			},
			dataHeatmap: {
				foldChangeValues: [],
				keggIds: [],
				tumorTypes: [],
				tumorTypesAbbr: [],
				pathways: []
			},
			isFetchingDataHeatmap: false
		};
	}

	componentDidMount() {
		axios
			.all([
				requestMappingGenePathwayOptions(),
				requestHeatmapFoldchangeData(this.state.currentComparisionType)
			])
			.then(resp =>
				this.setState({
					filterGeneOptions: resp[0].data,
					currentFilterGeneOption: resp[0].data[0],
					dataHeatmap: resp[1].data,
					isFetchingDataHeatmap: false
				})
			)
			.catch(_ => browserHistory.push(ROUTES.Error.push()));
	}

	updateCurrentComparisionType = (comparisionType: string) =>
		this.setState(
			{
				isFetchingDataHeatmap: true
			},
			() =>
				// get comparision data
				requestHeatmapFoldchangeData(comparisionType)
					.then(resp =>
						// update state
						this.setState(
							{
								currentComparisionType: comparisionType,
								dataHeatmap: resp.data,
								isFetchingDataHeatmap: false
							},
							// change url
							() => {
								browserHistory.push(
									ROUTES.Compare.push(comparisionType)
								);
								this.props.resetBarPlotData();
							}
						)
					)
					.catch(_ => browserHistory.push(ROUTES.Error.push()))
		);

	filterData = (
		currentFilterGeneOption: InterfaceMappingGenePathways,
		dataHeatmap: InterfaceFoldchangePerComparisiontype,
		filter: boolean
	) => {
		if (filter) {
			let y: InterfaceFilterDataHeatmap["y"] = [];
			let z: InterfaceFilterDataHeatmap["z"] = [];
			let text: InterfaceFilterDataHeatmap["text"] = [];
			let keggIds: InterfaceFilterDataHeatmap["keggIds"] = [];

			currentFilterGeneOption.pathwayIndexes.forEach(pathwayIndex => {
				y.push(dataHeatmap.pathways[pathwayIndex]);
				z.push(dataHeatmap.foldChangeValues[pathwayIndex]);
				text.push(dataHeatmap.tumorTypesAbbr[pathwayIndex]);
				keggIds.push(dataHeatmap.keggIds[pathwayIndex]);
			});

			return {
				x: dataHeatmap.tumorTypes,
				y: y,
				z: z,
				text: text,
				keggIds: keggIds,
				colorscale: ColorScale[this.state.currentComparisionType]
			};
		} else {
			return {
				x: dataHeatmap.tumorTypes,
				y: dataHeatmap.pathways,
				z: dataHeatmap.foldChangeValues,
				text: dataHeatmap.tumorTypesAbbr,
				keggIds: dataHeatmap.keggIds,
				colorscale: ColorScale[this.state.currentComparisionType]
			};
		}
	};

	render() {
		return this.state.dataHeatmap.tumorTypes.length > 0 ? (
			<Segment padded="very">
				<Header as="h3">
					<Icon name="th" />
					<Header.Content>
						Compare log<sub>2</sub> fold change across pathways
						<Header.Subheader>
							click on a square & scroll down to view pathway
						</Header.Subheader>
					</Header.Content>
				</Header>
				<Form>
					<Form.Field>
						<label>Select comparision type</label>
						<Dropdown
							selection
							loading={this.state.isFetchingDataHeatmap}
							options={this.state.comparisionTypeOptions}
							value={this.state.currentComparisionType}
							onChange={(_, { value }) =>
								this.updateCurrentComparisionType(
									value as string
								)
							}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
							label="Filter pathways in which a gene appears"
							onChange={() =>
								this.setState({
									toFilterGenes: !this.state.toFilterGenes
								})
							}
						/>
					</Form.Field>
					{this.state.toFilterGenes ? (
						<Form.Field>
							<label>Select a gene</label>
							<Dropdown
								selection
								search
								options={this.state.filterGeneOptions.map(
									val => {
										return {
											text: val.text,
											value: val.value
										};
									}
								)}
								defaultValue={
									this.state.currentFilterGeneOption.value
								}
								onChange={(_, { value }) =>
									this.setState({
										currentFilterGeneOption: this.state.filterGeneOptions.find(
											option => option.value === value
										)!
									})
								}
							/>
						</Form.Field>
					) : null}
				</Form>

				<HeatMap
					dataHeatmap={this.filterData(
						this.state.currentFilterGeneOption,
						this.state.dataHeatmap,
						this.state.toFilterGenes
					)}
					loading={this.props.isFetchingDataBarPlot}
					handleHeatmapClick={(yValue: string, tumorType: string) =>
						this.props.handleHeatmapClick(
							this.state.currentComparisionType,
							this.state.dataHeatmap.keggIds[
								this.state.dataHeatmap.pathways.indexOf(yValue)
							],
							tumorType
						)
					}
				/>
			</Segment>
		) : (
			<Dimmer active page>
				<Loader size="big" />
			</Dimmer>
		);
	}
}
