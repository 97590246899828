import React, { useState, useEffect } from "react";
import Plot from "react-plotlyjs-ts";
import { Segment, Dropdown } from "semantic-ui-react";
import { InterfaceExpression } from "../../utils/interfaces";
import { bar } from "./plotConfig.json";
import { BarplotColors } from "../../utils/constants";

interface Props {
	dataBarplot: InterfaceExpression[];
}

export const PlotBar = (props: Props) => {
	let { dataBarplot } = props;
	const [currentOption, updateCurrentOption] = useState<InterfaceExpression>(
		props.dataBarplot[0]
	);

	useEffect(() => {
		updateCurrentOption(dataBarplot[0]);
	}, [dataBarplot]);

	return (
		<Segment>
			<span>
				sort by{" "}
				<Dropdown
					inline
					icon="sort amount up"
					options={props.dataBarplot.map(val => {
						return { text: val.text, value: val.value };
					})}
					value={currentOption.value}
					onChange={(_, { value }) =>
						updateCurrentOption(
							props.dataBarplot.find(
								option => option.value === value
							)!
						)
					}
				/>
			</span>
			<div className="barplot">
				<Plot
					data={currentOption.expValues.map(expValue => {
						return {
							...expValue,
							marker: {
								color: BarplotColors[expValue.name],
								line: {
									color: BarplotColors[expValue.name],
									width: 1
								}
							},
							...bar.data
						};
					})}
					layout={{
						...bar.layout,
						width:
							currentOption.expValues[0].x.length *
							24 *
							currentOption.expValues.length
					}}
					config={bar.config}
				/>
			</div>
		</Segment>
	);
};
