const ROUTE_PREFIX = "/ui";

const ROUTE_HOME = ROUTE_PREFIX;
const ROUTE_BROWSE = ROUTE_PREFIX + "/browse";
const ROUTE_COMPARE = ROUTE_PREFIX + "/compare";
const ROUTE_TEAM = ROUTE_PREFIX + "/team";
const ROUTE_ERROR = ROUTE_PREFIX + "/error";

const PARAMS_BROWSE = {
	pathwayKeggIdFromRoute: "",
	tumorTypeFromRoute: ""
};
export type ROUTER_PARAMS_BROWSE = typeof PARAMS_BROWSE;

const PARAMS_COMPARE = {
	comparisionTypeFromRoute: "",
	pathwayKeggIdFromRoute: "",
	tumorTypeFromRoute: ""
};
export type ROUTER_PARAMS_COMPARE = typeof PARAMS_COMPARE;

export const ROUTES = {
	Home: {
		name: "Home",
		show: true,
		routes: [ROUTE_HOME],
		push: () => ROUTE_HOME.replace(/\/+$/, ""),
		match: (currentRoute: string) =>
			currentRoute.replace(/\/+$/, "") === ROUTE_HOME ||
			currentRoute.replace(/\/+$/, "") === ""
	},
	Browse: {
		name: "Browse Pathways",
		show: true,
		routes: [
			ROUTE_BROWSE,
			ROUTE_BROWSE + "/:" + Object.keys(PARAMS_BROWSE).join("/:")
		],
		push: (routePathwayKeggId: string = "", routeTumorType: string = "") =>
			[ROUTE_BROWSE, routePathwayKeggId, routeTumorType]
				.join("/")
				.replace(/\/+$/, ""),
		match: (currentRoute: string) =>
			currentRoute.replace(/\/+$/, "").startsWith(ROUTE_BROWSE)
	},
	Compare: {
		name: "Compare Pathways",
		show: true,
		routes: [
			ROUTE_COMPARE,
			ROUTE_COMPARE + "/:" + Object.keys(PARAMS_COMPARE)[0],
			ROUTE_COMPARE + "/:" + Object.keys(PARAMS_COMPARE).join("/:")
		],
		push: (
			comparisionType: string = "cs",
			routePathwayKeggId: string = "",
			routeTumorType: string = ""
		) =>
			[ROUTE_COMPARE, comparisionType, routePathwayKeggId, routeTumorType]
				.join("/")
				.replace(/\/+$/, ""),
		match: (currentRoute: string) =>
			currentRoute.replace(/\/+$/, "").startsWith(ROUTE_COMPARE)
	},
	Team: {
		name: "Team",
		show: true,
		routes: [ROUTE_TEAM],
		push: () => ROUTE_TEAM.replace(/\/+$/, ""),
		match: (currentRoute: string) =>
			currentRoute.replace(/\/+$/, "").startsWith(ROUTE_TEAM)
	},
	Error: {
		name: "Error",
		show: false,
		routes: [ROUTE_ERROR],
		push: () => ROUTE_ERROR.replace(/\/+$/, ""),
		match: (currentRoute: string) =>
			currentRoute.replace(/\/+$/, "").startsWith(ROUTE_ERROR)
	}
};

export const ROUTES_ARRAY = Object.values(ROUTES);
