import React from "react";
import { RouteComponentProps } from "react-router";

import { Grid } from "semantic-ui-react";
import { SearchMenu } from "./searchMenu";
import { PathwayBrowseImages } from "../../styled/pathwayBrowseImages";
import { browserHistory } from "../../../utils/browserHistory";
import { GridColumn } from "../../styled/gridColumn";

import { InterfaceExpression } from "../../../utils/interfaces";
import { requestBarplotExpdata } from "../../../utils/backendRequests";

import { ROUTES, ROUTER_PARAMS_BROWSE } from "../../../utils/routes";

interface Props extends RouteComponentProps<ROUTER_PARAMS_BROWSE> {}

export interface State {
	dataBarplot: InterfaceExpression[];
	isFetchingDataBarPlot: boolean;
	currentPathwayKeggId: string;
	currentTumorType: string;
}

export class SearchPage extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		// get params from route
		let {
			pathwayKeggIdFromRoute,
			tumorTypeFromRoute // maybe in lowercase
		} = this.props.match.params;

		this.state = {
			dataBarplot: [],
			isFetchingDataBarPlot: false,
			currentPathwayKeggId:
				pathwayKeggIdFromRoute !== undefined
					? pathwayKeggIdFromRoute
					: "",
			currentTumorType:
				tumorTypeFromRoute !== undefined ? tumorTypeFromRoute : ""
		};
	}

	// for window.scrollby
	imageRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		if (
			this.state.currentPathwayKeggId !== "" &&
			this.state.currentTumorType !== ""
		) {
			this.handleSubmitButton(
				this.state.currentPathwayKeggId,
				this.state.currentTumorType
			);
		}
	}

	handleSubmitButton = (pathwayKeggId: string, tumorType: string) =>
		// turn on loading
		this.setState(
			{
				isFetchingDataBarPlot: true
			},
			() =>
				// get data
				requestBarplotExpdata(pathwayKeggId, tumorType, "all")
					.then(response =>
						this.setState(
							{
								dataBarplot: response.data,
								isFetchingDataBarPlot: false,
								currentPathwayKeggId: pathwayKeggId,
								currentTumorType: tumorType
							},
							() => {
								// change url
								browserHistory.push(
									ROUTES.Browse.push(pathwayKeggId, tumorType)
								);

								// scrollTo image
								this.imageRef.current &&
									window.scrollTo(
										0,
										this.imageRef.current.offsetTop
									);
							}
						)
					)
					.catch(_ => browserHistory.push(ROUTES.Error.push()))
		);

	render() {
		return (
			<>
				<Grid.Row centered>
					<GridColumn>
						<SearchMenu
							pathwayKeggIdFromRoute={
								this.state.currentPathwayKeggId
							}
							tumorTypeFromRoute={this.state.currentTumorType}
							{...this.state}
							{...this}
						/>
					</GridColumn>
				</Grid.Row>
				{this.state.dataBarplot.length > 0 && (
					<>
						<div ref={this.imageRef} />
						<PathwayBrowseImages
							listComparisionType={["cs", "cn", "cc"]}
							{...this.state}
						/>
					</>
				)}
			</>
		);
	}
}
