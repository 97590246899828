import axios from "axios";

const API_URL = "/server/";
export const IMAGE_URL = "/images";

export const requestBannerData = () =>
	axios({
		method: "GET",
		url: API_URL + "v1/banner"
	});

export const requestBrowseOptions = () =>
	axios({
		method: "GET",
		url: API_URL + "v1/browse/options"
	});

export const requestMappingGenePathwayOptions = () =>
	axios({
		method: "GET",
		url: API_URL + "v1/gene/pathway/all"
	});

export const requestHeatmapFoldchangeData = (comparisionType: string) =>
	axios({
		method: "GET",
		url: API_URL + "v1/foldchange/" + comparisionType
	});

export const requestBarplotExpdata = (
	pathwayKeggId: string,
	tumorType: string,
	comparisionType: string
) =>
	axios({
		method: "GET",
		url:
			API_URL +
			"v1/exp/" +
			[pathwayKeggId, tumorType, comparisionType].join("/")
	});

export const requestImage = (
	comparisionType: string,
	pathwayKeggId: string,
	tumorType: string
) =>
	IMAGE_URL +
	"/hsa" +
	pathwayKeggId +
	"." +
	comparisionType +
	"_" +
	tumorType +
	".png";
