import React from "react";
import { Grid } from "semantic-ui-react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import { Navbar } from "../styled/navbar";

import { HomePage } from "./pageHome";
import { HeatMapPage } from "./pageHeatmap";
import { SearchPage } from "./pageSearch";
import { PageTeam } from "./pageTeam";
import { ErrorPage } from "./pageError";

import { browserHistory } from "../../utils/browserHistory";
import { ROUTES } from "../../utils/routes";

interface State {
	currentRoute: string;
}

export class AppRouter extends React.Component<{}, State> {
	constructor(props: {}) {
		super(props);

		this.state = {
			currentRoute: window.location.pathname
		};
	}

	componentDidMount() {
		//@ts-ignore
		this.unlisten = browserHistory.listen(location => {
			this.setState({ currentRoute: location.pathname });
		});
	}

	componentWillUnmount() {
		//@ts-ignore
		this.unlisten();
	}

	render() {
		return (
			<Grid stackable>
				<Navbar {...this.state} />
				<Router history={browserHistory}>
					<Switch>
						<Route
							exact
							path={ROUTES.Compare.routes}
							component={HeatMapPage}
						/>
						<Redirect
							exact
							from={ROUTES.Compare.routes[0]}
							to={ROUTES.Compare.push()}
						/>
						<Route
							exact
							path={ROUTES.Browse.routes}
							component={SearchPage}
						/>
						<Route
							exact
							path={ROUTES.Team.routes}
							component={PageTeam}
						/>
						<Route
							exact
							path={ROUTES.Error.routes}
							component={ErrorPage}
						/>
						<Route
							exact
							path={ROUTES.Home.routes}
							component={HomePage}
						/>
						<Route
							exact
							path={"/"}
							component={HomePage}
						/>
						<Redirect from="*" to={ROUTES.Home.push()} />
					</Switch>
				</Router>
			</Grid>
		);
	}
}
