import React from "react";
import {
	Grid,
	Menu,
	Header,
	Image,
	Responsive,
	Dropdown
} from "semantic-ui-react";

import { URL_NAVBAR_LOGO } from "../../utils/constants";
import { browserHistory } from "../../utils/browserHistory";
import { ROUTES_ARRAY, ROUTES } from "../../utils/routes";
import { GridColumn } from "./gridColumn";

interface Props {
	currentRoute: string;
}

const CommonMenu = () => (
	<>
		<Menu.Item>
			<Image
				size="mini"
				src={URL_NAVBAR_LOGO}
				alt="CaMP-logo"
				onClick={() => browserHistory.push(ROUTES.Home.push())}
			/>
		</Menu.Item>
		<Menu.Item>
			<Responsive maxWidth={1000}>
				<Header as="h2">
					<b>CaMP</b>
				</Header>
			</Responsive>
			<Responsive maxWidth={1200} minWidth={1000}>
				<Header as="h3">
					<b>CaMP</b> -<small> Cancer Metabolic Pathway Viewer</small>
				</Header>
			</Responsive>
			<Responsive minWidth={1200}>
				<Header as="h2">
					<b>CaMP</b> -<small> Cancer Metabolic Pathway Viewer</small>
				</Header>
			</Responsive>
		</Menu.Item>
	</>
);

const ComputerMenu = (props: { currentRoute: string }) => (
	<Menu fluid borderless>
		<CommonMenu />
		<Menu.Menu position="right">
			{ROUTES_ARRAY.map(
				val =>
					val.show && (
						<Menu.Item
							key={val.name}
							name={val.name}
							active={val.match(props.currentRoute)}
							onClick={() => browserHistory.push(val.push())}
						/>
					)
			)}
		</Menu.Menu>
	</Menu>
);

const MobileMenu = (props: { currentRoute: string }) => (
	<Menu fluid borderless>
		<CommonMenu />
		<Menu.Menu position="right">
			<Menu.Item>
				<Dropdown icon="bars">
					<Dropdown.Menu>
						{ROUTES_ARRAY.map(
							val =>
								val.show && (
									<Dropdown.Item
										key={val.name}
										text={val.name}
										selected={val.match(props.currentRoute)}
										onClick={() =>
											browserHistory.push(val.push())
										}
									/>
								)
						)}
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		</Menu.Menu>
	</Menu>
);

export const Navbar = (props: Props) => (
	<Grid.Row centered className="menuRow">
		<GridColumn>
			<Responsive minWidth={Responsive.onlyMobile.maxWidth}>
				<ComputerMenu currentRoute={props.currentRoute} />
			</Responsive>
			<Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
				<MobileMenu currentRoute={props.currentRoute} />
			</Responsive>
		</GridColumn>
	</Grid.Row>
);
