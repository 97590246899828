import React, { Fragment } from "react";
import styled from "@emotion/styled";
import {
	Card,
	Icon,
	List,
	Button,
	SemanticICONS,
	Grid,
	Responsive,
	Divider
} from "semantic-ui-react";
import { browserHistory } from "../../../utils/browserHistory";
import { GridColumn } from "../../styled/gridColumn";
import { ROUTES } from "../../../utils/routes";

interface Props {
	title: string;
	icon: SemanticICONS;
	buttonText: string;
	buttonHref: string;
	examples: Array<{
		href: string;
		text: string;
	}>;
	children: React.ReactNode;
}

const MoreWidthCard = styled(Card)`
	width: 480px !important;
`;

const FeatureCard = (props: Props) => (
	<MoreWidthCard>
		<Card.Content textAlign="left">
			<Card.Header as="h3">
				<Icon name={props.icon} />
				{props.title}
			</Card.Header>
			<Card.Description>
				{props.children}
				<List bulleted>
					<List.Item>cancer v/s stroma</List.Item>
					<List.Item>cancer v/s normal</List.Item>
					<List.Item>cancer v/s cell-line</List.Item>
				</List>
			</Card.Description>
		</Card.Content>
		<Card.Content>
			<Button
				size="mini"
				color="green"
				fluid
				onClick={() => browserHistory.push(props.buttonHref)}
			>
				{props.buttonText}
			</Button>
		</Card.Content>
		<Card.Content extra>
			<small>
				examples: <br />
				{props.examples.map(val => (
					<Fragment key={val.text}>
						<Icon name="linkify" />
						<a href={val.href}>{val.text}</a>
						<br />
					</Fragment>
				))}
			</small>
		</Card.Content>
	</MoreWidthCard>
);

export const Features = () => (
	<Grid.Row centered verticalAlign="middle">
		<GridColumn>
			<Card.Group centered>
				<FeatureCard
					title="Browse Pathways"
					icon="search"
					buttonText="Browse"
					buttonHref={ROUTES.Browse.push()}
					examples={[
						{
							text: "oxidative phosphorylation in LUAD",
							href: ROUTES.Browse.push("00190", "LUAD")
						},
						{
							text: "tryptophan metabolism in CRC",
							href: ROUTES.Browse.push("00380", "CRC")
						}
					]}
				>
					<p>
						View log
						<sub>2</sub> fold change data as well as metabolic
						pathway images, for a single pathway and tumor type,
						across :-
					</p>
				</FeatureCard>
				<Responsive minWidth={1100}>
					<Divider vertical>Or</Divider>
				</Responsive>
				<FeatureCard
					title="Compare Pathways"
					icon="th"
					buttonText="Compare"
					buttonHref={ROUTES.Compare.push()}
					examples={[
						{
							text:
								"oxidative phosphorylation in CRC in cancer vs stroma",
							href: ROUTES.Compare.push("cs", "00190", "CRC")
						},
						{
							text:
								"tryptophan metabolism in LUAD in cancer vs cell-line",
							href: ROUTES.Compare.push("cc", "00380", "LUAD")
						}
					]}
				>
					<p>
						View log<sub>2</sub> fold change data across tumors and
						pathways in a heatmap. Available for :-
					</p>
				</FeatureCard>
			</Card.Group>
		</GridColumn>
	</Grid.Row>
);
