import React, { Component } from "react";
import {
	Form,
	Dropdown,
	Button,
	Segment,
	Icon,
	Header,
	DropdownItemProps,
	Dimmer,
	Loader
} from "semantic-ui-react";
import { requestBrowseOptions } from "../../../utils/backendRequests";
import { browserHistory } from "../../../utils/browserHistory";
import { ROUTES } from "../../../utils/routes";

interface Props {
	handleSubmitButton: (pathwayKeggId: string, tumorType: string) => void;
	isFetchingDataBarPlot: boolean;
	pathwayKeggIdFromRoute: string;
	tumorTypeFromRoute: string;
}

interface State {
	pathwayOptions: DropdownItemProps[];
	tumorOptions: DropdownItemProps[];
	currentPathway: string;
	currentTumorType: string;
}

export class SearchMenu extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			pathwayOptions: [],
			tumorOptions: [],
			currentPathway: "",
			currentTumorType: ""
		};
	}

	componentDidMount() {
		requestBrowseOptions()
			.then(resp =>
				this.setState({
					...resp.data,
					currentPathway:
						this.props.pathwayKeggIdFromRoute === ""
							? this.state.currentPathway
							: resp.data["pathwayOptions"].find(
									(option: DropdownItemProps) =>
										option.value ===
										this.props.pathwayKeggIdFromRoute
							  ).value,
					currentTumorType:
						this.props.tumorTypeFromRoute === ""
							? this.state.currentTumorType
							: resp.data["tumorOptions"].find(
									(option: DropdownItemProps) =>
										option.value ===
										this.props.tumorTypeFromRoute
							  ).value
				})
			)
			.catch(_ => browserHistory.push(ROUTES.Error.push()));
	}

	render() {
		return this.state.pathwayOptions.length > 0 &&
			this.state.tumorOptions.length > 0 ? (
			<Segment padded="very">
				<Header as="h3">
					<Icon name="search" />
					Browse information about a single pathway
				</Header>
				<Form>
					<Form.Field>
						<label>Select pathway</label>
						<Dropdown
							fluid
							search
							selection
							upward={false}
							value={this.state.currentPathway}
							options={this.state.pathwayOptions}
							onChange={(_, { value }) =>
								this.setState({
									currentPathway: value as string
								})
							}
						/>
					</Form.Field>
					<Form.Field>
						<label>Select tumor type</label>
						<Dropdown
							fluid
							selection
							upward={false}
							value={this.state.currentTumorType}
							options={this.state.tumorOptions}
							onChange={(_, { value }) =>
								this.setState({
									currentTumorType: value as string
								})
							}
						/>
					</Form.Field>
					<Form.Field>
						<Button
							color="green"
							loading={this.props.isFetchingDataBarPlot}
							disabled={
								this.state.currentTumorType === "" ||
								this.state.currentPathway === ""
							}
							onClick={() =>
								this.props.handleSubmitButton(
									this.state.currentPathway,
									this.state.currentTumorType
								)
							}
						>
							Get the information
						</Button>
					</Form.Field>
				</Form>
			</Segment>
		) : (
			<Dimmer active page>
				<Loader size="big" />
			</Dimmer>
		);
	}
}
