import { DropdownItemProps } from "semantic-ui-react";

export const URL_NAVBAR_LOGO = require("../assets/logo.svg");

export const OPTIONS: DropdownItemProps[] = [
	{
		text: "Cancer v/s Stroma",
		arrtext: ["Cancer", "v/s", "Stroma"],
		value: "cs",
		color1: "red",
		color2: "blue"
	},
	{
		text: "Cancer v/s Normal",
		arrtext: ["Cancer", "v/s", "Normal"],
		value: "cn",
		color1: "red",
		color2: "green"
	},
	{
		text: "Cancer v/s Cell-line",
		arrtext: ["Cancer", "v/s", "Cell-line"],
		value: "cc",
		color1: "red",
		color2: "purple"
	}
];

export const BarplotColors: {
	[key: string]: any;
} = {
	cancer: "rgb(255, 116, 113)",
	stroma: "rgb(101, 128, 228)",
	normal: "rgb(12, 216, 97)",
	ccle: "rgb(128, 79, 135)"
};

export const ColorScale: {
	[key: string]: any;
} = {
	cs: [
		[0.0, "rgb(33, 79, 172)"],
		[0.125, "rgb(101, 128, 228)"],
		[0.25, "rgb(160, 180, 255)"],
		[0.375, "rgb(219, 236, 255)"],
		[0.5, "rgb(255,255, 255)"],
		[0.635, "rgb(255, 231, 220)"],
		[0.75, "rgb(255, 173, 165)"],
		[0.875, "rgb(255, 116, 113)"],
		[1, "rgb(178, 24, 43)"]
	],
	cn: [
		[0.0, "rgb(51, 160, 44)"],
		[0.125, "rgb(12, 216, 97)"],
		[0.25, "rgb(170, 255, 150)"],
		[0.375, "rgb(229, 255, 206)"],
		[0.5, "rgb(255,255, 255)"],
		[0.635, "rgb(255, 231, 220)"],
		[0.75, "rgb(255, 173, 165)"],
		[0.875, "rgb(255, 116, 113)"],
		[1, "rgb(178, 24, 43)"]
	],
	cc: [
		[0.0, "rgb(77, 32, 85)"],
		[0.125, "rgb(128, 79, 135)"],
		[0.25, "rgb(181, 129, 188)"],
		[0.375, "rgb(238, 183, 245)"],
		[0.5, "rgb(255,255, 255)"],
		[0.635, "rgb(255, 231, 220)"],
		[0.75, "rgb(255, 173, 165)"],
		[0.875, "rgb(255, 116, 113)"],
		[1, "rgb(178, 24, 43)"]
	]
};

export const Members: {
	imageUrl: string;
	header: string;
	subHeader: string;
	jobDescription: string;
	email: string;
}[] = [
	{
		imageUrl:
			"https://react.semantic-ui.com/images/avatar/large/matthew.png",
		header: "Probhonjon Baruah",
		subHeader: "Joined in 2019",
		jobDescription: "Bioinformatics Specialist",
		email: "baruahp@gis.a-star.edu.sg"
	},
	{
		imageUrl: "https://react.semantic-ui.com/images/avatar/large/molly.png",
		header: "Neha Rohatgi",
		subHeader: "Joined in 2017",
		jobDescription: "Post-Doc",
		email: "rohatgin@gis.a-star.edu.sg"
	},
	{
		imageUrl:
			"https://react.semantic-ui.com/images/avatar/large/daniel.jpg",
		header: "Anders Skanderup",
		subHeader: "Joined in 2015",
		jobDescription: "Principal Investigator",
		email: "skanderupamj@gis.a-star.edu.sg"
	}
];
