import React from "react";
import { Message, Grid, MessageProps } from "semantic-ui-react";
import { GridColumn } from "../../styled/gridColumn";
import { requestBannerData } from "../../../utils/backendRequests";

interface State {
	render: boolean;
	props?: MessageProps;
}

export class Banner extends React.Component<{}, State> {
	constructor(props: {}) {
		super(props);

		this.state = {
			render: false
		};
	}

	componentDidMount() {
		requestBannerData().then(resp => this.setState({ ...resp.data }));
	}

	render() {
		return this.state.render ? (
			<Grid.Row centered>
				<GridColumn textAlign="center">
					<Message {...this.state.props} />
				</GridColumn>
			</Grid.Row>
		) : null;
	}
}
