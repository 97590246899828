import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid } from "semantic-ui-react";
import { GridColumn } from "../../styled/gridColumn";
import { HeatMapMenu } from "./menu";
import { PathwayBrowseImages } from "../../styled/pathwayBrowseImages";
import { browserHistory } from "../../../utils/browserHistory";
import { InterfaceExpression } from "../../../utils/interfaces";
import { requestBarplotExpdata } from "../../../utils/backendRequests";
import { ROUTES, ROUTER_PARAMS_COMPARE } from "../../../utils/routes";

interface Props extends RouteComponentProps<ROUTER_PARAMS_COMPARE> {}

interface State {
	currentComparisionType: string;
	currentPathwayKeggId: string;
	currentTumorType: string;
	isFetchingDataBarPlot: boolean;
	dataBarplot: InterfaceExpression[];
}

export class HeatMapPage extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		// get params from route
		let {
			comparisionTypeFromRoute,
			pathwayKeggIdFromRoute,
			tumorTypeFromRoute // maybe in lowercase
		} = this.props.match.params;

		this.state = {
			currentComparisionType:
				comparisionTypeFromRoute !== undefined
					? comparisionTypeFromRoute
					: "",
			currentPathwayKeggId:
				pathwayKeggIdFromRoute !== undefined
					? pathwayKeggIdFromRoute
					: "",
			currentTumorType:
				tumorTypeFromRoute !== undefined ? tumorTypeFromRoute : "",
			isFetchingDataBarPlot: false,
			dataBarplot: []
		};
	}

	// for window.scrollby
	imageRef = React.createRef<HTMLDivElement>();

	/*
	 * get data for mapping genes to pathways
	 * get tumorOptions, pathwayOptions
	 *
	 * get heatmap data for each comparision type
	 * update default filtergene to first data in response
	 */
	componentDidMount() {
		if (
			this.state.currentPathwayKeggId !== "" &&
			this.state.currentTumorType !== ""
		) {
			this.handleHeatmapClick(
				this.state.currentComparisionType,
				this.state.currentPathwayKeggId,
				this.state.currentTumorType
			);
		}
	}

	resetBarPlotData = () => this.setState({ dataBarplot: [] });

	/**
	 * when heatmap is click
	 *
	 * turn on loading
	 * get barplot data, update state for image url
	 * change route
	 */
	handleHeatmapClick = (
		comparisionType: string,
		pathwayKeggId: string,
		tumorType: string
	) =>
		// turn on loading
		this.setState({ isFetchingDataBarPlot: true }, () =>
			// get barplot data
			requestBarplotExpdata(pathwayKeggId, tumorType, comparisionType)
				.then(resp =>
					// update state for image url
					// turn off loading
					this.setState(
						{
							currentComparisionType: comparisionType,
							currentPathwayKeggId: pathwayKeggId,
							currentTumorType: tumorType,
							isFetchingDataBarPlot: false,
							dataBarplot: resp.data
						},
						() => {
							// change url
							browserHistory.push(
								[
									ROUTES.Compare.routes[0],
									comparisionType,
									pathwayKeggId,
									tumorType
								].join("/")
							);

							// scrollTo image
							this.imageRef.current &&
								window.scrollTo(
									0,
									this.imageRef.current.offsetTop
								);
						}
					)
				)
				.catch(_ => browserHistory.push(ROUTES.Error.push()))
		);

	render() {
		return (
			<>
				<Grid.Row centered>
					<GridColumn textAlign="left">
						<HeatMapMenu {...this.state} {...this} />
					</GridColumn>
				</Grid.Row>
				{this.state.dataBarplot.length !== 0 && (
					<>
						<div ref={this.imageRef} />
						<PathwayBrowseImages
							listComparisionType={[
								this.state.currentComparisionType
							]}
							{...this.state}
						/>
					</>
				)}
			</>
		);
	}
}
